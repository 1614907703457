import {TimelineEntry} from './TimelineEntry';

export class TimelineResumedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Resumed', startDate, true);
    }

    getColor(): string {
        return '#aaa';
    }

}
