import {functions} from '../../firebase';
import {Timeline} from './timeline/Timeline';
import {TimelineEntry} from './timeline/TimelineEntry';
import {TimelineJoinedEntry} from './timeline/TimelineJoinedEntry';
import {TimelineNotifiedEntry} from './timeline/TimelineNotifiedEntry';
import {TimelineCalledEntry} from './timeline/TimelineCalledEntry';
import {TimelineSkippedEntry} from './timeline/TimelineSkippedEntry';
import {TimelineReadyEntry} from './timeline/TimelineReadyEntry';
import {TimelineStartedEntry} from './timeline/TimelineStartedEntry';
import {TimelineFinishedEntry} from './timeline/TimelineFinishedEntry';
import {TimelineCanceledEntry} from './timeline/TimelineCanceledEntry';
import {TimelineWaitlistedEntry} from './timeline/TimelineWaitlistedEntry';
import {TimelineDisconnectedEntry} from './timeline/TimelineDisconnectedEntry';
import {TimelinePausedEntry} from './timeline/TimelinePausedEntry';
import {TimelineResumedEntry} from './timeline/TimelineResumedEntry';
import {TimelineAbortedEntry} from './timeline/TimelineAbortedEntry';
import {TimelineLeftQueueEntry} from './timeline/TimelineLeftQueueEntry';

export enum SEARCH_TYPE {
    ROOM = 'Room',
    CANDIDATE = 'Candidate'
}

export class TimelineGenerator {

    async getEvents(eventId: string, searchType: SEARCH_TYPE, searchKey: string): Promise<IDomainEvent[]> {
        const findStoredEventsWithPayload = functions.httpsCallable('eventStore-api-findStoredEventsWithPayload');
        const data: {eventId: string, candidateId?: string, roomId?: string} = {eventId}
        if (searchType === SEARCH_TYPE.ROOM) {
            data.roomId = searchKey;
        } else if (searchType === SEARCH_TYPE.CANDIDATE) {
            data.candidateId = searchKey;
        }
        const result = await findStoredEventsWithPayload(data);
        return result.data.map((r: {id: string, createdAt: string, type: string, eventId: string, roomId: string, candidateId: string, payload: string}) => ({
            ...r,
            payload: JSON.parse(r.payload),
        }));
    }

    generateTimelineFromJSON(json: IImportedData) {
        const timeline = new Timeline();
        for (const item of json.actions) {
            let entry: TimelineEntry|null = null;
            if (item.action === 'Add') {
                entry = new TimelineJoinedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'NotifyNextCandidate') {
                entry = new TimelineNotifiedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'CallNextCandidate') {
                entry = new TimelineCalledEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'SkipNextCandidate') {
                entry = new TimelineSkippedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'ReadyNextCandidate') {
                entry = new TimelineReadyEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'StartInterview') {
                entry = new TimelineStartedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'FinishInterview') {
                entry = new TimelineFinishedEntry(item.candidateId, new Date(item.time));
            } else if ((item.action === 'CancelByInterviewer') || (item.action === 'CancelByCandidate')) {
                entry = new TimelineCanceledEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'Disconnected') {
                entry = new TimelineDisconnectedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'Aborted') {
                entry = new TimelineAbortedEntry(item.candidateId, new Date(item.time));
            } else if (item.action === 'RequestPause') {
                entry = new TimelinePausedEntry(item.candidateId, new Date(item.time));
            }
            if (entry != null) {
                timeline.add(entry);
            }
        }
        return timeline;
    }

    private getKey(event: IDomainEvent, searchType: SEARCH_TYPE): string {
        if (searchType === SEARCH_TYPE.ROOM) {
            return event?.candidateName ?? '';
        } else if (searchType === SEARCH_TYPE.CANDIDATE) {
            return `${event?.companyName} - ${event?.roomNumber}`;
        }
        return '';
    }

    generateTimeline(events: IDomainEvent[], searchType: SEARCH_TYPE) {
        const timeline = new Timeline();
        for (const event of events) {
            let entry: TimelineEntry|null = null;
            if (event.type.endsWith('candidate-assigned-to-room-event')) {
                entry = new TimelineJoinedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('next-candidate-notified-event')) {
                entry = new TimelineNotifiedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('next-candidate-called-event')) {
                entry = new TimelineCalledEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('next-candidate-skipped-event')) {
                entry = new TimelineSkippedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('next-candidate-ready-event')) {
                entry = new TimelineReadyEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('interviewer-joined-event')) {
                entry = new TimelineStartedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('interview-finished-event')) {
                entry = new TimelineFinishedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('interview-canceled-event')) {
                entry = new TimelineCanceledEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('interview-disconnected-event')) {
                entry = new TimelineDisconnectedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('candidate-waitlisted-event')) {
                if (this.getKey(event, searchType)?.length > 0) {
                    entry = new TimelineWaitlistedEntry(this.getKey(event, searchType), new Date(event.createdAt));
                }
            } else if (event.type.endsWith('interview-paused-event')) {
                entry = new TimelinePausedEntry('pause', new Date(event.createdAt));
            } else if (event.type.endsWith('interview-resumed-event')) {
                entry = new TimelineResumedEntry('pause', new Date(event.createdAt));
            } else if (event.type.endsWith('interview-aborted-event')) {
                entry = new TimelineAbortedEntry(this.getKey(event, searchType), new Date(event.createdAt));
            } else if (event.type.endsWith('candidate-left-from-queue-event')) {
                entry = new TimelineLeftQueueEntry(this.getKey(event, searchType), new Date(event.createdAt));
            }
            if (entry != null) {
                timeline.add(entry);
            }
        }
        return timeline;
    }
}

export interface IDomainEvent {
    id: string;
    createdAt: string;
    type: string;
    payload: {
        [key: string]: any;
    }
    roomId?: string;
    candidateId?: string;
    candidateName?: string;
    companyName?: string;
    roomNumber?: string;
}


export interface IImportedData {
    actions: IImportedEntry[];
}

export interface IImportedEntry {
    time: string;
    action: string;
    candidateId: string;
}

export default new TimelineGenerator();
