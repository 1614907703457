import React, {Fragment} from 'react';
import {functions} from '../firebase';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const dateFormatter = new Intl.DateTimeFormat('en', {
    day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
} as any);

export default class InterviewerEventList extends React.Component {

    state = {
        showLoading: false,
        interviewerEvents: [],
    };

    search = async (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({
            showLoading: true,
        });
        const formData = new FormData(e.target as HTMLFormElement);
        const searchEmail = formData.get('searchEmail') as string;
        const findInterviewersInfo = functions.httpsCallable('eventStore-api-findInterviewersInfo');
        let result;
        try {
            if ((searchEmail != null) && searchEmail.trim().length > 0) {
                result = await findInterviewersInfo({email: searchEmail.trim()});
            } else {
                result = await findInterviewersInfo();
            }
            this.setState({interviewerEvents: result.data ?? []});
        } finally {
            this.setState({showLoading: false});
        }
    }

    render() {
        return (
            <Fragment>
                <Form inline className="mb-3" onSubmit={this.search}>
                    <Form.Control placeholder="Interviewer Email" name="searchEmail" className="w-50 mr-2"/>
                    <Button type="submit">Search</Button>
                </Form>
                {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                {!this.state.showLoading && <Table responsive>
                    <thead>
                        <tr>
                            <th>Event Id</th>
                            <th>Email</th>
                            <th>Event Type</th>
                            <th>Performed At</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.interviewerEvents.map((e: InterviewerEvent) =>
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.email}</td>
                            <td>{e.type}</td>
                            <td>{dateFormatter.format(new Date(e.createdAt))}</td>
                        </tr>
                    )
                    }
                    </tbody>
                </Table>
                }
            </Fragment>
        )
    }

}

interface InterviewerEvent {

    id: string;
    createdAt: string;
    type: string;
    email: string;

}
