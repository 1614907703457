import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router';
import {functions} from '../firebase';
import ReactJson from 'react-json-view';
import ActionButton from '../ActionButton';
import {ToastsStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';

export default class SpeedInterviewEventPayload extends React.Component<RouteComponentProps<RouteParam> & {canReplay: boolean}, State> {

    state: State = {
        eventPayloadId: '',
        payload: null,
        showLoading: true,
    };

    componentDidMount() {
        const eventPayloadId = this.props.match.params.eventPayloadId;
        this.setState({eventPayloadId});

        functions.httpsCallable('eventStore-api-findStoredEvent')({id: eventPayloadId}).then(result => {
            this.setState({
                payload: (result.data.payload != null) ? JSON.parse(result.data.payload) : null,
            });
        }).catch(err => {
            ToastsStore.error(`Error ${JSON.stringify(err)} while searching for stored event with id [${eventPayloadId}]`);
        }).finally(() => this.setState({showLoading: false}));
    }

    componentWillUnmount() {}

    render() {
        return (
            <div>
                <h5>Payload for event {this.state.eventPayloadId}</h5>
                {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                <div className="container my-3">
                    {this.state.payload &&
                        <Fragment>
                            <ReactJson src={this.state.payload} displayDataTypes={false} />
                            <div className="my-3">
                                {this.props.canReplay && <ActionButton handleClick={this.replayPayload} label="Replay Payload" actionData={null} />}
                            </div>
                        </Fragment>
                    }
                </div>
            </div>

        )
    }

    replayPayload = async () => {
        const replaySpeedInterviewEventStoreEntry = functions.httpsCallable('eventStore-api-replayEvent');
        return replaySpeedInterviewEventStoreEntry({
            eventId: this.state.eventPayloadId,
        }).then(() => {
            ToastsStore.success(`The payload has been replayed.`);
            return Promise.resolve();
        }).catch(error => {
            ToastsStore.error(`Error [${error.code}] while replaying payload [${this.state.eventPayloadId}]: ${error.message}`);
            return Promise.reject(error);
        });
    }

}

interface State {
    eventPayloadId: string;
    payload: any;
    showLoading: boolean;
}

interface RouteParam {
    eventPayloadId: string;
}
