import React from 'react';
// @ts-ignore
import {JsonEditor as Editor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import {Chart} from 'react-google-charts';
import timelineGenerator from './service/timelineGenerator';
import Button from 'react-bootstrap/Button';

interface State {
    sourceJson: any;
    showChart: boolean;
    rows: any[];
}

interface RouteParam {
    eventId: string;
}

const COLUMNS = [
    { type: 'string', id: 'Candidate' },
    { type: 'string', id: 'Type' },
    { type: 'string', id: 'style', role: 'style' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
];

export default class TimelineCreator extends React.Component {


    state: State = {
        sourceJson: null,
        showChart: false,
        rows: [],
    };

    componentDidMount() {}

    componentWillUnmount() {}

    generateTimeline = () => {
        const timeline = timelineGenerator.generateTimelineFromJSON(this.state.sourceJson);
        this.setState({
            rows: timeline.toChartRows(),
            showChart: true,
        });
    }

    chartOptions = {
        timeline: {
            groupByRowLabel: true,
        },
        hAxis: {
            format: 'mm:ss',
        },
    };

    render() {
        return (
            <div>
                <div>Paste the JSON generated by scheduler here:</div>
                <Editor value={this.state.sourceJson} onChange={(json: any) => this.setState({sourceJson: json})} mode="text"/>
                <div style={{ marginTop: '20px' }}>
                    <Button onClick={this.generateTimeline}>Generate</Button>
                </div>
                <div style={{ marginTop: '20px'}}>
                    { this.state.showChart ? <Chart chartType="Timeline" data={[COLUMNS, ...this.state.rows]} options={this.chartOptions} width="100%" height="80vh" /> : null}
                </div>
            </div>
        )
    }

}
