import React from 'react';
import {RouteComponentProps} from 'react-router';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Chart} from 'react-google-charts';
import timelineGenerator, {IDomainEvent, SEARCH_TYPE} from './service/timelineGenerator';
import {Link} from 'react-router-dom';
import Slider from 'rc-slider';
import moment from 'moment';
import 'rc-slider/assets/index.css';
import speedInterviewService from './service/speedInterviewService';

const ROOM_COLUMNS = [
    { type: 'string', id: 'Candidate' },
    { type: 'string', id: 'Type' },
    { type: 'string', id: 'style', role: 'style' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
];

const CANDIDATE_COLUMNS = [
    { type: 'string', id: 'Room' },
    { type: 'string', id: 'Type' },
    { type: 'string', id: 'style', role: 'style' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
];

interface State {
    eventId: string;
    searchValue: string;
    searchType: SEARCH_TYPE;
    rows: any[];
    showChart: boolean,
    loading: boolean,
    timelineStart: Date;
    timelineEnd: Date;
    timelineMaxDuration: number,
    selectedTimelineValue: number;
    events: IDomainEvent[],
    information: string;
}

interface RouteParam {
    eventId: string;
}

const createSliderWithTooltip = (Slider as any).createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

export default class Timeline extends React.Component<RouteComponentProps<RouteParam>, State> {


    state: State = {
        eventId: '',
        searchValue: '',
        searchType: SEARCH_TYPE.ROOM,
        rows: [],
        showChart: false,
        loading: false,
        timelineStart: new Date(),
        timelineEnd: new Date(),
        timelineMaxDuration: 0,
        selectedTimelineValue: 0,
        events: [],
        information: '',
    };

    componentDidMount() {
        const updatedState: Partial<State> = {
            eventId: this.props.match.params.eventId
        };
        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('searchType')) {
            updatedState.searchType = searchParams.get('searchType') as any;
        }
        if (searchParams.has('searchValue')) {
            updatedState.searchValue = searchParams.get('searchValue') as any;
        }
        this.setState(updatedState as any, this.loadTimeline);
    }

    async componentDidUpdate(prevProps: Readonly<RouteComponentProps<RouteParam>>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.location.search !== prevProps?.location?.search) {
            await this.loadTimeline();
        }
    }

    componentWillUnmount() {}

    loadTimeline = async () => {
        try {
            if (this.state.searchValue != null) {
                this.setState({loading: true});
                const trimmedSearchValue = this.state.searchValue.replace(/\s/g, '');
                if (this.state.searchType === SEARCH_TYPE.CANDIDATE) {
                    this.setState({information: await speedInterviewService.getEmail(trimmedSearchValue)});
                } else if (this.state.searchType === SEARCH_TYPE.ROOM) {
                    this.setState({information: await speedInterviewService.findInformationByRoomId(this.state.eventId, trimmedSearchValue)});
                }
                const events = await timelineGenerator.getEvents(this.state.eventId, this.state.searchType, trimmedSearchValue);
                if (events.length > 0) {
                    const timelineStart = new Date(events[0].createdAt);
                    const timelineEnd = new Date(events[events.length - 1].createdAt);
                    const maxDuration = moment(timelineEnd).diff(timelineStart, 's');
                    this.setState({
                        timelineStart, timelineEnd, events,
                        timelineMaxDuration: maxDuration,
                        selectedTimelineValue: maxDuration,
                    });
                }
                const timeline = timelineGenerator.generateTimeline(events, this.state.searchType);
                this.setState({
                    rows: timeline.toChartRows(),
                    searchValue: trimmedSearchValue,
                    showChart: true,
                });
            } else {
                console.log(`Nothing to search for!`);
            }
        } finally {
            this.setState({loading: false});
        }
    }

    run = async () => {
        if (this.state.searchValue) {
            this.props.history.push(`/speedInterviewEvent/${this.state.eventId}/timeline?searchType=${this.state.searchType}&searchValue=${this.state.searchValue}`);
        }
        await this.loadTimeline();
    };

    chartOptions = {
        timeline: {
            groupByRowLabel: true,
        },
        hAxis: {
            format: 'mm:ss',
        },
    };

    columns = () => {
        if (this.state.searchType === SEARCH_TYPE.ROOM) {
            return ROOM_COLUMNS;
        } else if (this.state.searchType === SEARCH_TYPE.CANDIDATE) {
            return CANDIDATE_COLUMNS;
        }
        throw new Error(`Invalid search type: [${this.state.searchType}]`);
    };

    sliderToolTip = (sliderValue: number) => {
        return moment(this.state.timelineStart).add(sliderValue, 's').format('HH:mm:ss');
    }

    onSliderAfterChange = (value: number) => {
        this.setState({
            selectedTimelineValue: value,
        });
        const effectiveEnd = moment(this.state.timelineStart).add(value, 's');
        const timeline = timelineGenerator.generateTimeline(this.state.events.filter(e => moment(e.createdAt).isSameOrBefore(effectiveEnd)), this.state.searchType);
        this.setState({
            rows: timeline.toChartRows(),
            showChart: true,
        });
    }

    onSliderChange = (value: number) => {
        this.setState({
            selectedTimelineValue: value,
        });
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Row className="mb-3">
                        <Form.Check inline label="Room" type="radio" id="searchTypeRoom" name="searchType" onChange={() => this.setState({searchType: SEARCH_TYPE.ROOM})}  checked={this.state.searchType === SEARCH_TYPE.ROOM} />
                        <Form.Check inline label="Candidate" type="radio" id="searchTypeCandidate" name="searchType" onChange={() => this.setState({searchType: SEARCH_TYPE.CANDIDATE})} checked={this.state.searchType === SEARCH_TYPE.CANDIDATE} />
                        <Form.Control placeholder="Search keyword" className="w-50 mr-2" onChange={(v: any) => this.setState({searchValue: v.target.value})} value={this.state.searchValue} />
                        <Button onClick={this.run}>Run</Button>
                        <Link to={`/speedInterviewEvent/${this.state.eventId}/roominfo`} target="_blank" className="ml-auto">
                            <Button variant="light">Rooms Details</Button>
                        </Link>
                    </Form.Row>
                </Form>
                <div className="my-2">
                    <SliderWithTooltip min={0} max={this.state.timelineMaxDuration} value={this.state.selectedTimelineValue}
                                       tipFormatter={this.sliderToolTip} onAfterChange={this.onSliderAfterChange} onChange={this.onSliderChange}/>
                </div>
                <div className="my-3">
                    {this.state.information}
                </div>

                { this.state.showChart && this.state.rows.length > 0 ? <Chart chartType="Timeline" data={[this.columns(), ...this.state.rows]} options={this.chartOptions} width="100%" height="80vh" /> : null}

                { this.state.loading ? <div>Please wait while while we're preparing your timeline...</div> : null}

            </div>
        )
    }

}
