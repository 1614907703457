import {TimelineEntry} from './TimelineEntry';

export class TimelineJoinedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Joined', startDate, true);
    }

    getColor(): string {
        return '#B58DCC';
    }

}
