import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import firebase, {functions} from './firebase';
import {Redirect} from 'react-router';

const Admin = () => {

    useEffect(() => {
        const loginWithCustomToken = () => {
            const requestCustomToken = functions.httpsCallable('eventStore-api-requestCustomToken');
            const data: {passcode: string} = {passcode: hashedEventId};
            return requestCustomToken(data).then((result) => {
                doLogin(result.data);
            }).catch((err) => {
                console.error(`Error custome token`, err);
            });
        }
        loginWithCustomToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [loginState, setLoginState] = useState(() => false);

    const {hashedEventId} = useParams() as any;

    function doLogin(customToken: string) {
        console.log(`Trying to login with custom token [${customToken}]...`);
        // return setPersistence().then(() => {
            return firebase.auth().signInWithCustomToken(customToken).then((user) => {
                console.log(`Logged in with custom token`, user);
                setTimeout(() => {
                    setLoginState(true);
                }, 3000);
                console.log(`loggedIn value [${loginState}]`);
            }).catch((err) => {
                console.error(`Error when try to login with custom token`, err);
            });
        // });
    }

    // function setPersistence(): Promise<any> {
    //     return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    // }

    return (
        <div>
            <div>Loading...</div>
            {console.log(`loginState`, loginState)}
            {loginState && <Redirect to={`/speedInterviewEvent/${hashedEventId}/roominfo`}/>}
        </div>
    )
}

export default Admin
