import React, {Fragment} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {functions} from '../firebase';
import Modal from 'react-bootstrap/Modal';
import {ToastsStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';
import SchedulerTimeline from './SchedulerTimeline';

export default class SchedulerList extends React.Component<any, State> {

    state: State = {
        roomId: '',
        showCloseRoomWarning: false,
        showLoading: false,
        showTimeline: false,
        snapshots: [],
        selectedSnapshot: null,
        displayedSnapshots: [],
    };

    componentDidMount() {}

    componentWillUnmount() {}

    view = async () => {
        if (!this.state.roomId) {
            ToastsStore.error('Room id is required!');
            return;
        }
        this.setState({
            showLoading: true,
            showTimeline: false,
            snapshots: [],
            displayedSnapshots: [],
            selectedSnapshot: null,
        });
        const result: {data: {rows: {snapshot_id: string, created_at: string}[]}} = await functions.httpsCallable('eventStore-api-getSnapshots')({roomId: this.state.roomId.trim()});
        this.setState({
            showLoading: false,
            showTimeline: true,
            snapshots: result.data.rows,
            selectedSnapshot: result.data?.rows[0] ?? null,
        });
    };

    snapshotSelectionChanged = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            selectedSnapshot: this.state.snapshots.find(s => s.snapshot_id === e.target.value) ?? null,
        });
    }

    viewSnapshot = () => {
        if (this.state.selectedSnapshot == null) {
            console.error(`There's no selected snapshot id!`);
            return;
        }
        const snapshot = this.state.selectedSnapshot;
        const displayedSnapshots = this.state.displayedSnapshots;
        if (!displayedSnapshots.includes(snapshot)) {
            displayedSnapshots.push(snapshot);
        }
        this.setState({
            displayedSnapshots: displayedSnapshots,
        });
    }

    removeSnapshot= (snapshotId: string) => {
        this.setState({
            displayedSnapshots: this.state.displayedSnapshots.filter(s => s.snapshot_id !== snapshotId),
        });
    }

    showCloseRoomWarning = () => {
        this.setState({
            showCloseRoomWarning: true,
        });
    }

    closeRoomWarning = () => {
        this.setState({
            showCloseRoomWarning: false,
        });
    }

    closeRoom = () => {
        const roomId = this.state.roomId;
        if (!roomId) {
            ToastsStore.error(`Missing roomId!`);
            return;
        }
        return functions.httpsCallable('eventStore-api-closeRoom')({
            roomId, reason: 'MANUALLY_CLOSED'
        }).then(() => {
            ToastsStore.success(`Room [${roomId}] is closing`);
        }).catch(error => {
            ToastsStore.error(`Error [${error.code}] while closing room [${roomId}].`);
        }).finally(() => {
            this.closeRoomWarning();
        });
    };

    render() {
        return (
            <Fragment>
                <Modal show={this.state.showCloseRoomWarning} onHide={this.closeRoomWarning} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Room termination Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Do you really want to terminate this room?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeRoomWarning}>No</Button>
                        <Button variant="danger" onClick={this.closeRoom}>Yes</Button>
                    </Modal.Footer>
                </Modal>
                <Form>
                    <Form.Group controlId="formRoomId">
                        <Form.Label>Room ID</Form.Label>
                        <Form.Control placeholder="Enter room id" onChange={(v: any) => this.setState({roomId: v.target.value})} value={this.state.roomId}/>
                    </Form.Group>
                    <Button variant="primary" className="mr-2" onClick={this.view}>View</Button>
                    <Button variant="danger" className="mr-2" onClick={this.showCloseRoomWarning}>Terminate</Button>
                </Form>
                <div className="mt-3 w-100">
                    {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                    {this.state.showTimeline &&
                        <div className="w-100">
                            <div>
                                <span className="mr-2">Select snapshot time:</span>
                                <select className="mr-2" onChange={this.snapshotSelectionChanged}>
                                    {this.state.snapshots.map(s => (
                                        <option value={s.snapshot_id} key={s.snapshot_id}>{new Date(s.created_at).toLocaleTimeString()}</option>
                                    ))}
                                </select>
                                <Button variant="outline-primary" size="sm" onClick={this.viewSnapshot}>View</Button>
                            </div>
                            {this.state.displayedSnapshots.map(s => (
                                <div className="mt-2" key={s.snapshot_id}>
                                    <SchedulerTimeline snapshotId={s.snapshot_id} atTime={s.created_at} onRemoved={this.removeSnapshot} />
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Fragment>
        )
    }

}

interface State {
    roomId: string;
    showCloseRoomWarning: boolean;
    showLoading: boolean;
    showTimeline: boolean;
    snapshots: {snapshot_id: string, created_at: string}[];
    selectedSnapshot: {snapshot_id: string, created_at: string} | null;
    displayedSnapshots: {snapshot_id: string, created_at: string}[];
}
