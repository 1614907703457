import {TimelineEntry} from './TimelineEntry';

export class TimelineCalledEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Called', startDate);
    }

    getColor(): string {
        return '#CCA5A1';
    }

}
