import {TimelineEntry} from './TimelineEntry';

export class TimelineAbortedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Aborted', startDate, true);
    }

    getColor(): string {
        return '#E64337';
    }

}
