import {TimelineEntry} from './TimelineEntry';

export class TimelineSkippedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Skipped', startDate, true);
    }

    getColor(): string {
        return '#CC1D25';
    }

}
