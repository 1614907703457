import React from 'react';
import './App.css';
import firebase from './firebase';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import SpeedInterviewEventList from './speedInterviewEvent/SpeedInterviewEventList';
import Login from './Login';
import {LinkContainer} from 'react-router-bootstrap';
import Home from './Home';
import Container from 'react-bootstrap/Container';
import Logout from './Logout';
import {User} from 'firebase';
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts';
import SpeedInterviewEventDetail from './speedInterviewEvent/SpeedInterviewEventDetail';
import SpeedInterviewEventPayload from './speedInterviewEvent/SpeedInterviewEventPayload';
import InterviewerEventList from './interviewer/InterviewerEventList';
import Timeline from './speedInterviewEvent/Timeline';
import RoomInfo from './speedInterviewEvent/roomInfo/RoomInfo';
import TimelineCreator from './speedInterviewEvent/TimelineCreator';
import SchedulerList from './scheduler/SchedulerList';
import Report from './report/Report';
import EOEventList from './eo/EOEventList';
import Admin from './Admin';


export default class App extends React.Component {

    private unregisterAuthObserver!: firebase.Unsubscribe;

    state = {
        isSignedIn: true,
        isAdmin: false,
        canReplay: false,
        isEO: false,
    };

    // @ts-ignore
    PrivateRoute = ({ component: Component, ...rest }) => {

        return (
            <Route
                {...rest}
                render={props =>
                    this.state.isSignedIn ? (
                        this.state.isAdmin || this.state.isEO ? (<Component {...props} canReplay={this.state.canReplay} />) : (<div>Please wait while we're whitelisting your user...</div>)
                    ) : (
                        <Redirect to="/login" />
                    )
                }
            />
        );
    };

    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user: User|null) => {
            this.setState({
                isSignedIn: !!user,
            });
            if (user != null) {
                user.getIdTokenResult().then(result => {
                    this.setState({
                        isAdmin: result.claims.admin as boolean,
                        isEO: result.claims.eventHashedId ? true : false,
                        canReplay: user.email === 'jocki.hireplace@gmail.com' || user.email === 'support@hireplace.com',
                    });
                });
                const userCreationTime = new Date(user.metadata.creationTime as string);
                if ((new Date().getTime() - userCreationTime.getTime()) < 20000) {
                    setTimeout(() => {
                        user.getIdTokenResult(true).then(result => {
                            console.log('Id token updated');
                            this.setState({
                                isAdmin: result.claims.admin as boolean,
                                isEO: result.claims.eventHashedId ? true : false,
                                canReplay: user.email === 'jocki.hireplace@gmail.com' || user.email === 'support@hireplace.com',
                            });
                        });
                    }, 20000);
                }
            }
        });
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        return (
            <Router>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} lightBackground />
                <Navbar bg="dark" variant="dark" expand="lg">
                    <LinkContainer to="/">
                        <Navbar.Brand>Event Store</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <LinkContainer to={this.state.isAdmin ? "/speedInterviewEvent" : "#"}>
                                <Nav.Link>Speed Interview Event</Nav.Link>
                            </LinkContainer>
                            {this.state.isAdmin && <LinkContainer to="/eo">
                                <Nav.Link>EO</Nav.Link>
                            </LinkContainer>}
                            {this.state.isAdmin && <LinkContainer to="/interviewer">
                                <Nav.Link>Interviewer</Nav.Link>
                            </LinkContainer>}
                            {this.state.isAdmin && <LinkContainer to="/scheduler">
                                <Nav.Link>Scheduler</Nav.Link>
                            </LinkContainer>}
                            {this.state.isAdmin && <LinkContainer to="/report">
                                <Nav.Link>Report</Nav.Link>
                            </LinkContainer>}
                        </Nav>
                        {this.state.isSignedIn ?
                            (
                                <LinkContainer to="/logout">
                                    <Button>Logout</Button>
                                </LinkContainer>
                            )
                            :
                            (
                                <LinkContainer to="/login">
                                    <Button>Login</Button>
                                </LinkContainer>
                            )
                        }
                    </Navbar.Collapse>
                </Navbar>

                <Container className="my-3">
                    <Route path="/" exact component={Home} />
                    <Route path="/admin/:hashedEventId" component={Admin}/>
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Switch>
                        <this.PrivateRoute path="/speedInterviewEvent/:eventId/timeline" component={Timeline} />
                        <this.PrivateRoute path="/speedInterviewEvent/:eventId/roominfo" component={RoomInfo} />
                        <this.PrivateRoute path="/speedInterviewEvent/:eventId/:eventPayloadId" component={SpeedInterviewEventPayload} />
                        <this.PrivateRoute path="/speedInterviewEvent/:eventId" component={SpeedInterviewEventDetail} />
                        <this.PrivateRoute path="/speedInterviewEvent" component={SpeedInterviewEventList} />
                        <this.PrivateRoute path="/eo/:eventPayloadId" component={SpeedInterviewEventPayload} />
                        <this.PrivateRoute path="/eo" component={EOEventList} />
                        <this.PrivateRoute path="/interviewer" component={InterviewerEventList} />
                        <this.PrivateRoute path="/scheduler" component={SchedulerList} />
                        <this.PrivateRoute path="/tools/timeline" component={TimelineCreator} />
                        <this.PrivateRoute path="/report" component={Report} />
                    </Switch>
                </Container>
            </Router>
        );
    }

}
