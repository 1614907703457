import React from 'react';
import {Chart} from 'react-google-charts';
import {functions} from '../firebase';
import Button from 'react-bootstrap/Button';

interface State {
    init: boolean;
    rows: any[];
    showChart: boolean,
    loading: boolean,
    height: string;
    expandButtonCaption: string;
}

interface ISnapshotRecord {
    index: number,
    candidate_id: string,
    candidate_name: string,
    join_time: string,
    interview_time: string,
    start_time: string,
    finish_time: string,
    type: string,
    offer?: {
        to_candidate_id: string;
        reply: string;
        expired_at: string;
    }
    filled_by?: string,
}

interface Props {
    snapshotId: string;
    atTime: string;
    onRemoved: (snapshotId: string, event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SNAPSHOT_CACHE = new Map<string, Array<Array<(string|Date)>>>();

export default class SchedulerTimeline extends React.Component<Props, State> {

    state: State = {
        init: false,
        rows: [],
        showChart: false,
        loading: false,
        height: '400px',
        expandButtonCaption: 'Expand',
    };

    componentDidMount() {
        this.loadTimeline().then(() => console.log(`Timeline has been loaded!`));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.snapshotId !== this.props.snapshotId) {
            this.setState({
                showChart: false,
                loading: false,
            });
            this.loadTimeline().then(() => console.log(`Timeline has been loaded!`));
        }
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        if (!this.state.init) {
            return true;
        }
        if (this.props.snapshotId !== nextProps.snapshotId) {
            return true;
        }
        return this.state.height !== nextState.height;

    }

    loadTimeline = async () => {
        try {
            this.setState({loading: true});
            const snapshotId = this.props.snapshotId?.trim();
            console.log(`Loading snapshot [${snapshotId}]`);
            let timelineData: Array<Array<(string|Date)>>;
            if (SNAPSHOT_CACHE.has(snapshotId)) {
                timelineData = SNAPSHOT_CACHE.get(snapshotId)!;
            } else {
                const result = await functions.httpsCallable('eventStore-api-getRoomSnapshot')({snapshotId});
                const rows = result.data.rows as ISnapshotRecord[];
                console.log(`Query result:`, rows);
                timelineData = [];
                for (const row of rows) {
                    let color = '#74E8AA';
                    if (['SKIPPED', 'CANCELED_BY_INTERVIEWER', 'CANCELED_BY_CANDIDATE', 'ABORTED'].includes(row.type)) {
                        color = '#CC1D25'
                    } else if (['IDLE', 'PAUSED', 'RESUMED', 'PAUSE_PENDING'].includes(row.type)) {
                        color = '#aaa';
                    }
                    timelineData.push([`${row.index} - ${row.candidate_name ?? row.candidate_id}`, 'JOINED', '#B58DCC', new Date(row.join_time), new Date(row.join_time)]);
                    timelineData.push([`${row.index} - ${row.candidate_name ?? row.candidate_id}`, 'HOLDING', '#DAA520', new Date(row.interview_time), new Date(row.start_time)]);
                    timelineData.push([`${row.index} - ${row.candidate_name ?? row.candidate_id}`, row.type, color, new Date(row.start_time), new Date(row.finish_time)]);
                    if (row.filled_by != null) {
                        timelineData.push([`${row.index} - ${row.candidate_name ?? row.candidate_id}`, `${row.filled_by} left`, color, new Date(row.start_time), new Date(row.finish_time)]);
                    }
                    if (row.offer != null) {
                        const expiredAt = new Date(row.offer.expired_at);
                        const startedAt = new Date(expiredAt.getTime() - 10000);
                        timelineData.push([`${row.index} - ${row.candidate_name}`, `Offering to ${rows.filter(r => r.candidate_id === row?.offer?.to_candidate_id).map(r => r.candidate_name).pop()}`, '#aaa', startedAt, expiredAt]);
                    }
                }
                SNAPSHOT_CACHE.set(snapshotId, timelineData);
            }
            if (timelineData?.length > 0) {
                this.setState({
                    rows: timelineData,
                    showChart: true,
                });
            }
        } finally {
            this.setState({loading: false, init: true});
        }
    }

    expand = () => {
        if (this.state.height === '400px') {
            this.setState({
                height: '100vh',
                expandButtonCaption: 'Collapse',
            });
        } else {
            this.setState({
                height: '400px',
                expandButtonCaption: 'Expand',
            });
        }
    }

    remove = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onRemoved(this.props.snapshotId, e);
    }

    chartOptions = {
        timeline: {
            groupByRowLabel: true,
        },
        hAxis: {
            format: 'mm:ss',
        },
    };

    COLUMNS = [
        { type: 'string', id: 'Candidate' },
        { type: 'string', id: 'Type' },
        { type: 'string', id: 'style', role: 'style' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
    ];

    render() {
        return (
            <div>
                { (this.state.showChart && this.state.rows.length > 0) && <div>
                    <div className="small my-3">
                        <span className="mr-3">Snapshot id: {this.props.snapshotId} ({new Date(this.props.atTime).toLocaleTimeString()})</span>
                        <Button size="sm" variant="outline-secondary" className="mr-2" onClick={this.expand}>{this.state.expandButtonCaption}</Button>
                        <Button size="sm" variant="outline-secondary" onClick={this.remove}>Remove</Button>
                    </div>
                    <Chart chartType="Timeline" data={[this.COLUMNS, ...this.state.rows]} options={this.chartOptions} width="100%" height={this.state.height} key={this.props.snapshotId + this.state.height}/>
                </div> }
                { this.state.loading ? <div>Please wait while while we're preparing your timeline...</div> : null }
            </div>
        );
    }

};
