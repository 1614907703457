import {TimelineEntry} from './TimelineEntry';

export class TimelineNotifiedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Notified', startDate);
    }

    getColor(): string {
        return '#2bb8b3';
    }

}
