import {TimelineEntry} from './TimelineEntry';

export class TimelineStartedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Started', startDate);
    }

    getColor(): string {
        return '#74E8AA';
    }

}
