import {db} from '../../firebase';

export class SpeedInterviewService {

    private usernameMap = new Map<string, string>();
    private companyMap = new Map<string, string>();
    private roomNumberMap = new Map<string, string>();

    async getUsername(userId?: string): Promise<string> {
        if (userId == null) {
            return '(missing)';
        }
        if (this.usernameMap.has(userId)) {
            return this.usernameMap.get(userId)!;
        }
        let result = userId;
        const snapshot = await db.doc(`user_candidates/${userId}`).get();
        if (snapshot.exists) {
            const user = snapshot.data();
            result = user?.nickname ?? user?.name ?? user?.username ?? userId;
        }
        this.usernameMap.set(userId, result);
        return result;
    }

    async getEmail(userId?: string): Promise<string> {
        if ((userId == null) || (userId === '')) {
            return '(email not found)';
        }
        const snapshot = await db.doc(`user_candidates/${userId}`).get();
        if (snapshot.exists) {
            const user = snapshot.data();
            return user?.email ?? '(email not found)';
        }
        return '(email not found)';
    }

    async getCompanyName(eventId: string, companyId?: string): Promise<string> {
        if (companyId == null) {
            return '(missing)';
        }
        if (this.companyMap.has(companyId)) {
            return this.companyMap.get(companyId)!;
        }
        const snapshot = await db.doc(`is_events/${eventId}/companies/${companyId}`).get();
        const result = snapshot.get('name');
        this.companyMap.set(companyId, result);
        return result ?? companyId;
    }

    async findInformationByRoomId(eventId: string, roomId?: string): Promise<string> {
        if ((roomId == null) || (roomId === '')) {
            return '(room not found)';
        }
        const companiesSnapshot = await db.collection(`is_events/${eventId}/companies`).get();
        for (const companySnapshot of companiesSnapshot.docs) {
            if (companySnapshot.exists) {
                const roomSnapshot = await companySnapshot.ref.collection('rooms').doc(roomId).get();
                if (roomSnapshot.exists) {
                    const companyName = companySnapshot.get('name') ?? '(no company name)';
                    const interviewers: {email: string}[] = roomSnapshot.get('interviewers') ?? [];
                    return `${companyName} - ${interviewers.map(i => i.email).join(', ')}`
                }
            }
        }
        return '(room not found)';
    }

    async getRoomNumber(eventId: string, companyId?: string, roomId?: string): Promise<string> {
        if ((companyId == null) || (roomId == null)) {
            return roomId ?? '(missing)';
        }
        if (this.roomNumberMap.has(roomId)) {
            return this.roomNumberMap.get(roomId)!;
        }
        const snapshot = await db.doc(`is_events/${eventId}/companies/${companyId}/rooms/${roomId}`).get();
        const result = `${snapshot.get('roomNumber')}`;
        this.roomNumberMap.set(roomId, result);
        return result ?? roomId;
    }

}

export default new SpeedInterviewService();
