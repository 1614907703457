import {TimelineEntry} from './TimelineEntry';

export class TimelineReadyEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Ready', startDate);
    }

    getColor(): string {
        return '#805B58';
    }

}
