import React from 'react';
import './App.css';
import firebase from './firebase';
import {StyledFirebaseAuth} from 'react-firebaseui';
import {Redirect} from 'react-router';

export default class Login extends React.Component {

    state = {
        isSignedIn: false,
        from: '/speedInterviewEvent',
    };

    // noinspection JSUnusedGlobalSymbols
    private authUiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/signedIn',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false
        }
    };

    private unregisterAuthObserver!: firebase.Unsubscribe;

    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => this.setState({isSignedIn: !!user})
        );
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        return (
            <div>
                <StyledFirebaseAuth uiConfig={this.authUiConfig} firebaseAuth={firebase.auth()}/>
                {this.state.isSignedIn && <Redirect to={this.state.from} />}
            </div>
        );
    }

}
