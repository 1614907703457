export abstract class TimelineEntry {

    private _finishDate: Date|null = null;

    protected constructor(public key: string, public type: string, public startDate: Date, public standalone = false) {
        if (this.standalone) {
            this._finishDate = startDate;
        }
    }

    get finishDate(): Date {
        return this._finishDate ?? this.startDate;
    }

    handleNextEntry(entry: TimelineEntry) {
        if ((this._finishDate == null) && (this.key === entry.key)) {
            this._finishDate = entry.startDate;
        }
    }

    abstract getColor(): string;

    asString(): string {
        return `TimelineEntry(key[${this.key}],type[${this.type}],startDate[${this.startDate.toISOString()}],finishDate[${this.finishDate.toISOString()}],standalone=[${this.standalone}])`;
    }

    toChartRow(): any[] {
        return [this.key, this.type, this.getColor(), this.startDate, this.finishDate];
    }

}
