/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router';
import {functions} from '../../firebase';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import './RoomInfo.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom';
import {ToastsStore} from 'react-toasts';
import Spinner from 'react-bootstrap/Spinner';

export default class RoomInfo extends React.Component<RouteComponentProps<RouteParam>, State> {

    state: State = {
        eventId: '',
        eventName: '',
        eventImage: '',
        eventFinishedAt: new Date(),
        companies: new Map<string, IRoomInfoEntry[]>(),
        loading: true,
        selectedCandidateStatusEntry: null,
        selectedRoomInfoEntry: null,
        showAbortInterview: false,
        showMoveDialog: false,
        showAssignCandidateDialog: false,
        showMessageDialog: false,
        assignCandidateDialogData: {companyName: '', companyId: '', roomNumber: 1, roomId: '', roles: []},
        assignCandidateFormValue: {eventId: null, companyId: null, roomId: null, selectedRoleId: null, candidateIds: []},
        messageFormValue: {candidateIds: [], interviewerEmails: [], text: null, sender: null},
        candidatesOnEvent: [],
        interviewersOnEvent: [],
        closingRoomId: '',
        closingRoomConfirmation: false,
        closingRoomData: [],
        openOrCloseStageProcessing: false,
        creatingStage: false,
    };

    async componentDidMount() {
        const eventId = this.props.match.params.eventId;
        // const interviewStatus = ['INTERVIEWED', 'QUEUED'];
        if (eventId == null) {
            return;
        }
        const result = (await functions.httpsCallable('eventStore-api-findRoomInfo')({eventId})).data as IRoomInfo;
        const candidatesOnEvent = (await functions.httpsCallable('eventStore-api-getEventCandidates')({eventId})).data;
        const interviewersOnEvent = (await functions.httpsCallable('eventStore-api-getEventInterviewers')({eventId})).data;
        const companies = new Map<string, IRoomInfoEntry[]>();
        for (const roomInfoEntry of result?.companies) {
            if (companies.has(roomInfoEntry.companyId)) {
                companies.get(roomInfoEntry.companyId)?.push(roomInfoEntry);
            } else {
                companies.set(roomInfoEntry.companyId, [roomInfoEntry]);
            }
        }
        this.setState({
            eventId,
            eventName: result?.eventName,
            eventImage: result?.eventLogo,
            eventFinishedAt: result?.eventFinishedAt ? new Date(result.eventFinishedAt): new Date(),
            loading: false,
            companies,
            candidatesOnEvent: this.sortCandidate((candidatesOnEvent.rows as any[])),
            interviewersOnEvent: interviewersOnEvent.rows,
        });
        console.log(`candidates`, this.state.candidatesOnEvent);
        console.log(`interviewers`, this.state.interviewersOnEvent);
    }

    componentWillUnmount() {}

    roomsForMove = () => {
        if (this.state.selectedRoomInfoEntry == null) {
            return [];
        }
        const companyId = this.state.selectedRoomInfoEntry.companyId;
        const roomId = this.state.selectedRoomInfoEntry.roomId;
        return this.state.companies.get(companyId)?.filter(e => (e.roomId !== roomId))?.map(e => ({id: e.roomId, roomNumber: e.roomNumber})) ?? [];
    }

    showMoveDialog = (roomInfoEntry: IRoomInfoEntry, candidateStatusEntry: ICandidateStatusEntry) => {
        this.setState({
            selectedRoomInfoEntry: roomInfoEntry,
            selectedCandidateStatusEntry: candidateStatusEntry,
            showMoveDialog: true,
        });
    }

    closeMoveDialog = () => {
        this.setState({
            selectedRoomInfoEntry: null,
            selectedCandidateStatusEntry: null,
            showMoveDialog: false,
        })
    }

    targetRoomSelectionChanged = (e: any) => {
        this.setState({
            selectedTargetRoomToMove: (e.target.value === '') ? null : e.target.value,
        });
    }

    selectedRoleForAssignCandidateChanged = (e: any) => {
        this.setState({
            selectedRoleForAssignCandidate: (e.target.value === '') ? null : e.target.value,
            assignCandidateFormValue: {
                ...this.state.assignCandidateFormValue,
                selectedRoleId: e.target.value,
            }
        });
        console.log(`assignCandidateFormValue`, this.state.assignCandidateFormValue);
    }

    selectedCandidateForAssignChanged = (e: any) => {
        console.log(`event`, e);
        if (e.target.checked) {
            this.state.assignCandidateFormValue?.candidateIds.push(e.target.value);
            this.setState({
                assignCandidateFormValue: {
                    ...this.state.assignCandidateFormValue,
                    candidateIds: this.state.assignCandidateFormValue?.candidateIds,
                }
            })
        } else {
            const idx = this.state.assignCandidateFormValue.candidateIds.indexOf(e.target.value);
            if (idx > -1) {
                this.state.assignCandidateFormValue.candidateIds.splice(idx, 1);
                this.setState({
                    assignCandidateFormValue: {
                        ...this.state.assignCandidateFormValue,
                        candidateIds: this.state.assignCandidateFormValue?.candidateIds,
                    }
                });
            }
        }
        console.log(`assignCandidateFormValue`, this.state.assignCandidateFormValue);
    }

    selectedMessageTargetChanged = (e: any) => {
        console.log(`event`, e);
        if (e.target.checked) {
            this.state.messageFormValue.candidateIds.push(e.target.value);
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    candidateIds: this.state.messageFormValue?.candidateIds,
                }
            })
        } else {
            const idx = this.state.messageFormValue.candidateIds.indexOf(e.target.value);
            if (idx > -1) {
                this.state.messageFormValue.candidateIds.splice(idx, 1);
                this.setState({
                    messageFormValue: {
                        ...this.state.messageFormValue,
                        candidateIds: this.state.messageFormValue?.candidateIds,
                    }
                });
            }
        }
        console.log(`messageFormValue`, this.state.messageFormValue);
    }

    selectAllCandidates = (e: any) => {
        console.log(`select all candidates checkbox`, e.target.checked);
        const isChecked = e.target.checked;
        if (isChecked) {
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    candidateIds: [],
                }
            })
            this.state.candidatesOnEvent.forEach((candidate) => {
                console.log(candidate);
                this.state.messageFormValue.candidateIds.push(candidate.id);
                this.setState({
                    messageFormValue: {
                        ...this.state.messageFormValue,
                        candidateIds: this.state.messageFormValue.candidateIds,
                    }
                });
            });
        } else {
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    candidateIds: [],
                }
            });
        }
        console.log(this.state.messageFormValue);
    }

    selectAllInterviewers = (e: any) => {
        console.log(`select all candidates checkbox`, e.target.checked);
        const isChecked = e.target.checked;
        if (isChecked) {
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    interviewerEmails: [],
                }
            })
            this.state.interviewersOnEvent.forEach((interviewer) => {
                console.log(interviewer);
                this.state.messageFormValue.interviewerEmails.push(interviewer);
                this.setState({
                    messageFormValue: {
                        ...this.state.messageFormValue,
                        interviewerEmails: this.state.messageFormValue.interviewerEmails,
                    }
                });
            });
        } else {
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    interviewerEmails: [],
                }
            });
        }
        console.log(this.state.messageFormValue);
    }

    selectedMessageInterviewerChanged = (e: any) => {
        console.log(`event`, e);
        if (e.target.checked) {
            this.state.messageFormValue.interviewerEmails.push(e.target.value);
            this.setState({
                messageFormValue: {
                    ...this.state.messageFormValue,
                    interviewerEmails: this.state.messageFormValue?.interviewerEmails,
                }
            })
        } else {
            const idx = this.state.messageFormValue.interviewerEmails.indexOf(e.target.value);
            if (idx > -1) {
                this.state.messageFormValue.interviewerEmails.splice(idx, 1);
                this.setState({
                    messageFormValue: {
                        ...this.state.messageFormValue,
                        interviewerEmails: this.state.messageFormValue?.interviewerEmails,
                    }
                });
            }
        }
        console.log(`messageFormValue`, this.state.messageFormValue);
    }

    messageToSendChanged = (e: any) => {
        this.setState({
            messageFormValue: {
                ...this.state.messageFormValue,
                text: e.target.value,
            }
        })
    }

    messageSenderNameChanged = (e: any) => {
        this.setState({
            messageFormValue: {
                ...this.state.messageFormValue,
                sender: e.target.value,
            }        
        })
    }

    moveCandidate = () => {
        this._moveCandidate(false);
    }

    moveCandidateImmediately = () => {
        this._moveCandidate(true);
    }

    private _moveCandidate(immediately: boolean) {
        if (this.state.selectedTargetRoomToMove == null) {
            console.error(`Invalid room id!`);
            return;
        }
        const fnMoveCandidate = functions.httpsCallable('eventStore-api-moveCandidate');
        const eventId = this.state.eventId;
        const companyId = this.state.selectedRoomInfoEntry?.companyId ?? null;
        const roomId = this.state.selectedRoomInfoEntry?.roomId ?? null;
        const candidateId = this.state.selectedCandidateStatusEntry?.userId ?? null;
        const destinationRoomId = this.state.selectedTargetRoomToMove;
        this.setState({
            selectedRoomInfoEntry: null,
            selectedCandidateStatusEntry: null,
            showMoveDialog: false,
        });
        return fnMoveCandidate({
            eventId, companyId, roomId, candidateId, destinationRoomId, notifyCandidate: !immediately,
        }).then(() => {
            ToastsStore.success(`Candidate [${candidateId}] has been moved!`);
            return Promise.resolve();
        }).catch(error => {
            ToastsStore.error(`Error [${error.code}] while moving candidate [${candidateId}]: ${error.message}`);
            return Promise.reject(error);
        });
    }

    showAbortInterviewWarning = (roomInfoEntry: IRoomInfoEntry, candidateStatusEntry: ICandidateStatusEntry) => {
        this.setState({
            selectedRoomInfoEntry: roomInfoEntry,
            selectedCandidateStatusEntry: candidateStatusEntry,
            showAbortInterview: true,
        });
    }

    closeAbortInterview = () => {
        this.setState({
            selectedRoomInfoEntry: null,
            selectedCandidateStatusEntry: null,
            showAbortInterview: false,
        });
    }

    abortInterview = () => {
        const fnAbortInterview = functions.httpsCallable('eventStore-api-abortInterview');
        const eventId = this.state.eventId;
        const companyId = this.state.selectedRoomInfoEntry?.companyId;
        const roomId = this.state.selectedRoomInfoEntry?.roomId;
        const candidateId = this.state.selectedCandidateStatusEntry?.userId;
        this.setState({
            selectedRoomInfoEntry: null,
            selectedCandidateStatusEntry: null,
            showAbortInterview: false,
        });
        return fnAbortInterview({
            eventId, companyId, roomId, candidateId,
        }).then(() => {
            ToastsStore.success(`Interview for candidate [${candidateId}] has been aborted!`);
            return Promise.resolve();
        }).catch(error => {
            ToastsStore.error(`Error [${error.code}] while aborting interview for candidate [${candidateId}]: ${error.message}`);
            return Promise.reject(error);
        });
    }

    private getSupportedRole(entry: IRoomInfoEntry): string {
        return entry?.jobRoles?.map(j => j.title)?.join(', ') ?? '';
    }

    private getRoleName(entry: IRoomInfoEntry, roleId: string): string {
        return entry?.jobRoles?.filter(j => `${j.id}` === `${roleId}`).map(j => j.title).pop() ?? '';
    }

    private getInterviewers(entry: IRoomInfoEntry) {
        return `${entry?.performedBy?.name ?? ''} (${entry?.performedBy?.email ?? ''})`;
    }

    private roomClosedInformation(entry: IRoomInfoEntry) {
        const diff = Math.floor((new Date(entry.closedAt).getTime() - this.state.eventFinishedAt.getTime()) / 60000);
        const minutes = Math.abs(Math.floor(diff % 60));
        const hours = Math.abs(Math.floor(diff / 60));
        const condition = (diff < 0) ? ' before ' : ' after ';
        return <span className="ml-auto small">Closed {hours > 0 ? hours : ''} {hours > 0 ? 'hours' : ''} {minutes} minutes <em>{condition}</em> event is done, reason: <em>{entry.closedReason}</em></span>
    }

    private sortCandidate(candidate: any[]): any[] {
        return candidate.sort((a, b) => {
            const candidateA = (a.email as string).toUpperCase();
            const candidateB = (b.email as string).toUpperCase();
            return candidateA.localeCompare(candidateB);
        })
    }

    showAssignCandidateDialog = (companyName: string, companyId: string, roomNumber: number, roomId: string, roles: {id: string, title: string}[]) => {
        this.setState({
            assignCandidateDialogData: {
                companyName: companyName,
                companyId: companyId,
                roomNumber: roomNumber,
                roomId: roomId,
                roles: roles,
            },
            assignCandidateFormValue: {
                ...this.state.assignCandidateFormValue,
                eventId: this.state.eventId,
                companyId: companyId,
                roomId: roomId,
            },
            showAssignCandidateDialog: true,
        });
        console.log(this.state.assignCandidateFormValue);
    }

    isAssignCandidateFormValid = () => {
        return (
            (this.state.assignCandidateFormValue) &&
            (this.state.assignCandidateFormValue.eventId != null) &&
            (this.state.assignCandidateFormValue.companyId != null) &&
            (this.state.assignCandidateFormValue.roomId != null) &&
            (this.state.assignCandidateFormValue.selectedRoleId != null) &&
            (this.state.assignCandidateFormValue.candidateIds.length > 0)
        )
    }

    isMessageFormValid = () => {
        return (
            (this.state.messageFormValue) &&
            (this.state.messageFormValue.candidateIds.length > 0 || this.state.messageFormValue.interviewerEmails.length > 0) &&
            (this.state.messageFormValue.text != null && this.state.messageFormValue.text !== '')
        )
    }

    closeAssignCandidateDialog = () => {
        this.setState({
            assignCandidateDialogData: {
                companyName: '',
                companyId: '',
                roomNumber: 1,
                roomId: '',
                roles: [],
            },
            assignCandidateFormValue: {eventId: null, companyId: null, roomId: null, selectedRoleId: null, candidateIds: []},
            showAssignCandidateDialog: false,
        });
    }

    assignCandidatesToRoom = () => {
        this._assignCandidatesToRoom();
    }

    private _assignCandidatesToRoom = () => {
        const fnAssignCandidateToRoom = functions.httpsCallable('eventStore-api-assignCandidatesToRoom');
        const eventId = this.state.assignCandidateFormValue.eventId;
        const companyId = this.state.assignCandidateFormValue.companyId;
        const roomId = this.state.assignCandidateFormValue.roomId;
        const jobRoleId = this.state.assignCandidateFormValue.selectedRoleId;
        const candidateIds = this.state.assignCandidateFormValue.candidateIds;
        this.setState({
            assignCandidateDialogData: {
                companyName: '',
                companyId: '',
                roomNumber: 1,
                roomId: '',
                roles: [],
            },
            assignCandidateFormValue: {eventId: null, companyId: null, roomId: null, selectedRoleId: null, candidateIds: []},
            showAssignCandidateDialog: false,
        });
        return fnAssignCandidateToRoom({
            eventId, companyId, roomId, jobRoleId, candidateIds,
        }).then(() => {
            if (candidateIds.length > 1) {
                ToastsStore.success(`Candidates have been assigned!`);
            } else if (candidateIds.length === 1) {
                ToastsStore.success(`Candidate has been assigned!`);
            }
            return Promise.resolve();
        }).catch((error) => {
            ToastsStore.error(`Error [${error.code}] while assigning candidate, please try again later.`);
            return Promise.reject(error);
        });
    }

    sendMessage = () => {
        this._sendMessage();
    }

    _sendMessage = () => {
        const fnSendMessage = functions.httpsCallable('eventStore-api-sendBroadcastMessage');
        const candidateIds = this.state.messageFormValue.candidateIds;
        const interviewerEmails = this.state.messageFormValue.interviewerEmails;
        const text = this.state.messageFormValue.text;
        const sender = this.state.messageFormValue.sender;
        this.setState({
            messageFormValue: {candidateIds: [], interviewerEmails: [], text: null, sender: null},
            showMessageDialog: false,
        });
        return fnSendMessage({candidateIds, interviewerEmails, text, sender}).then(() => {
            ToastsStore.success(`Message has been sent!`);
            return Promise.resolve();
        }).catch((error) => {
            ToastsStore.error(`Error [${error.code}] when trying to send the message, please try again later.`);
            return Promise.reject(error);
        });
    }

    showMessageDialog = () => {
        this.setState({
            showMessageDialog: true,
        });
    }

    closeMessageDialog = () => {
        this.setState({
            messageFormValue: {candidateIds: [], interviewerEmails: [], text: null, sender: null},
            showMessageDialog: false,
        })
    }

    isCandidateAlreadyChecked = (candidateId: string) => {
        return this.state.messageFormValue.candidateIds.includes(candidateId);
    }

    isInterviewerAlreadyChecked = (interviewerEmail: string) => {
        return this.state.messageFormValue.interviewerEmails.includes(interviewerEmail);
    }

    openCloseRoomConfirmation = (roomId: string, companyIndex: number, entryIndex: number) => {
        this.setState({
            closingRoomConfirmation: true,
            closingRoomData: [roomId, companyIndex, entryIndex],
        })
    }

    exitCloseRoomConfirmation = () => {
        this.setState({
            closingRoomConfirmation: false,
            closingRoomData: [],
        })
    }

    _closeRoom = () => {
        const roomId = this.state.closingRoomData[0];
        const companyIndex = this.state.closingRoomData[1];
        const entryIndex = this.state.closingRoomData[2];
        this.setState({closingRoomId: roomId, closingRoomConfirmation: false, });
        const fnCloseRoom = functions.httpsCallable('eventStore-api-closeRoom');
        return fnCloseRoom({roomId}).then(() => {
            ToastsStore.success(`Room has been closed!`);
            this.setState({closingRoomId: '', closingRoomData: []});
            this.updateCompaniesState(companyIndex, entryIndex);
            return Promise.resolve();
        }).catch((error) => {
            ToastsStore.error(`Error [${error.code}] when trying to close the room, please try again later.`);
            this.setState({closingRoomId: ''});
            return Promise.reject(error);
        });
    }

    updateCompaniesState = (companyIndex: number, entryIndex: number) => {
        const companies = Array.from(this.state.companies.entries());
        const closedAt = new Date();
        companies[companyIndex][1][entryIndex].closedAt = closedAt.toISOString();
        companies[companyIndex][1][entryIndex].closedReason = "MANUALLY_CLOSED";
        const mappedCompanies = new Map<string, IRoomInfoEntry[]>();
        for (const company of companies) {
            mappedCompanies.set(company[0] as string, company[1] as IRoomInfoEntry[]);
        }
        return this.setState({companies: mappedCompanies});
    }

    openOrCloseStage = () => {
        const eventId = this.state.eventId;
        this.setState({openOrCloseStageProcessing: true});
        const fnOpenOrCloseStage = functions.httpsCallable('eventStore-api-openOrCloseStage');
        return fnOpenOrCloseStage({eventId}).then((val) => {
            if (!val) {
                ToastsStore.success(`Stage has been closed!`);
            } else {
                ToastsStore.success(`Stage has been Opened!`);
            }
            this.setState({openOrCloseStageProcessing: false});
            return Promise.resolve();
        }).catch((error) => {
            ToastsStore.error(`Error [${error.code}] when trying to close / open the stage, please try again later.`);
            this.setState({openOrCloseStageProcessing: false});
            return Promise.reject(error);
        });
    }

    createStage = () => {
        const eventId = this.state.eventId;
        this.setState({creatingStage: true});
        const fnCreateStage = functions.httpsCallable('eventStore-api-createStage');
        return fnCreateStage({eventId}).then(() => {
            ToastsStore.success('Stage has been created!');
            this.setState({creatingStage: false});
            return Promise.resolve();
        }).catch((error) => {
            ToastsStore.error(`Error [${error.code}] when trying to create the stage, please try again later.`);
            this.setState({creatingStage: false});
            return Promise.reject(error);
        });
    }

    render() {
        if (this.state.loading) {
            return <div>Preparing your data... <Spinner animation="grow"/></div>;
        }
        return (
            <Container>
                <Modal show={this.state.showAbortInterview} onHide={this.closeAbortInterview} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Abort Interview Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        The candidate <strong>{this.state.selectedCandidateStatusEntry?.name}</strong> status is <strong>{this.state.selectedCandidateStatusEntry?.status}</strong>.
                        Do you really want to abort the interview?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeAbortInterview}>No</Button>
                        <Button variant="danger" onClick={this.abortInterview}>Yes</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showMoveDialog} onHide={this.closeMoveDialog} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Move Candidate To Another Room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.roomsForMove().length === 0 ?
                            <Alert variant='warning'>This operation is not available because there is no other rooms to move the candidate!</Alert> :
                            <Form>
                                <Form.Group controlId='roomsForMove'>
                                    <Form.Label>Select destination room</Form.Label>
                                    <Form.Control as="select" onChange={this.targetRoomSelectionChanged} defaultValue={''}>
                                        <option value=''>(Select One)</option>
                                        {this.roomsForMove().map(r => {
                                            return (<option value={r.id} key={r.id}>{r.roomNumber}</option>)
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeMoveDialog}>Close</Button>
                        {(this.roomsForMove().length > 0) && <Button variant="primary" onClick={this.moveCandidate}>Offer & Move</Button>}
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showAssignCandidateDialog} onHide={this.closeAssignCandidateDialog} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign candidate to {this.state.assignCandidateDialogData.companyName} room #{this.state.assignCandidateDialogData.roomNumber}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="assign-form">
                            <div>
                                <Form>
                                    <Form.Group controlId='assignCandidateForm'>
                                        <Form.Label>Choose role : </Form.Label>
                                        <Form.Control as="select" defaultValue={''} onChange={this.selectedRoleForAssignCandidateChanged}>
                                            <option value=''>(Select One)</option>
                                            {this.state.assignCandidateDialogData.roles.map(role => {
                                                return (<option value={role.id} key={role.title}>{role.title}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Label className="mt-2">Select candidates :</Form.Label>
                                        <div className="candidate-section">
                                            {this.state.candidatesOnEvent.filter((candidate) => candidate.id).map((candidate) => {
                                                return (
                                                    <div key={`candidate-${candidate.id}`} className="mb-1">
                                                        <Form.Check type={`checkbox`} id={`checkbox-candidate-${candidate.id}`} label={candidate.email} value={candidate.id} onChange={this.selectedCandidateForAssignChanged}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeAssignCandidateDialog}>Close</Button>
                        {(this.isAssignCandidateFormValid()) && <Button variant="primary" onClick={this.assignCandidatesToRoom}>Assign candidates to room</Button>}
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showMessageDialog} onHide={this.closeMessageDialog} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Send announcement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form>
                                <Form.Group controlId='messageForm'>
                                    <Form.Label className='w-100'>Select candidates : 
                                        <span className='float-right'>
                                        <Form.Check type={`checkbox`} id={`select-all-candidates`} label={'Select all'} onChange={this.selectAllCandidates}/>
                                        </span>
                                    </Form.Label>
                                    <div className="candidate-section candidate-section-message">
                                        {this.state.candidatesOnEvent.filter((candidate) => candidate.id).map((candidate) => {
                                            return (
                                                <div key={`candidate-${candidate.id}`} className="mb-1">
                                                    <Form.Check type={`checkbox`} id={`checkbox-candidate-${candidate.id}`} label={candidate.email} value={candidate.id} onChange={this.selectedMessageTargetChanged} checked={this.isCandidateAlreadyChecked(candidate.id)}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <Form.Label className="mt-2 w-100">Select interviewers :
                                        <span className='float-right'>
                                            <Form.Check type={`checkbox`} id={`select-all-interviewers`} label={'Select all'} onChange={this.selectAllInterviewers}/>
                                        </span>
                                    </Form.Label>
                                    <div className="candidate-section candidate-section-message">
                                        {this.state.interviewersOnEvent.map((interviewer) => {
                                            return (
                                                <div key={`interviewer-${interviewer}`} className="mb-1">
                                                    <Form.Check type={`checkbox`} id={`checkbox-candidate-${interviewer}`} label={interviewer} value={interviewer} onChange={this.selectedMessageInterviewerChanged} checked={this.isInterviewerAlreadyChecked(interviewer)}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <Form.Control className="mt-2 w-50" type="text" placeholder="name sender of the message" onChange={this.messageSenderNameChanged} />
                                    <Form.Control as="textarea" placeholder="Type your message :" className="mt-2" onChange={this.messageToSendChanged}></Form.Control>
                                </Form.Group>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeMessageDialog}>Close</Button>
                        {(this.isMessageFormValid()) && <Button variant="primary" onClick={this.sendMessage}>Send message</Button>}
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.closingRoomConfirmation} onHide={this.exitCloseRoomConfirmation} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Closing room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.closingRoomData.length > 0 && <div><p>Are you sure want to close {Array.from(this.state.companies.entries())[this.state.closingRoomData[1]][1][0].companyName} room #{Array.from(this.state.companies.entries())[this.state.closingRoomData[1]][1][0].roomNumber}</p></div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.exitCloseRoomConfirmation}>exit</Button>
                        {<Button variant="primary" onClick={this._closeRoom}>Close room</Button>}
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col md="2" className="text-right">
                        <Image src={this.state.eventImage} className="logo mr-2" thumbnail fluid/>
                    </Col>
                    <Col className="align-self-md-center">
                        <h5>{this.state.eventName}</h5> 
                        <small>({this.state.eventId})</small>
                        <small className="ml-3"><a href="#" onClick={() => this.openOrCloseStage()}>{this.state.openOrCloseStageProcessing ? "Closing stage..." : "Close stage"}</a></small>
                        <small className="ml-3"><a href="#" onClick={() => this.createStage()}>{this.state.creatingStage ? "Creating stage..." : "Create stage"}</a></small>
                    </Col>
                    <Col className="text-end">
                        <div></div>
                        <small>
                            <a href="#" onClick={() => this.showMessageDialog()}>Announcement</a>
                        </small>
                    </Col>
                </Row>
                <hr />
                {Array.from(this.state.companies.entries()).map((e, companyIndex) => {
                    return (
                        <Fragment key={e[0]}>
                            <Row className="mt-4">
                                <Col md="1">
                                    <Image src={e[1][0].companyLogo} className="logo" />
                                </Col>
                                <Col className="align-self-md-center">
                                    <strong>{e[1][0].companyName}</strong> <small>({e[0]})</small>
                                </Col>
                            </Row>
                            {e[1].map((entry, entryIndex) => {
                                return (
                                    <Row className="mt-2" key={entry.roomId}>
                                        <Container className="ml-3">
                                            <Row className="align-items-center">Room:
                                                <span className="ml-2"><Link to={`/speedInterviewEvent/${this.state.eventId}/timeline?searchType=Room&searchValue=${entry.roomId}`} target="_blank">{entry.roomId}</Link></span>
                                                <small className="ml-2">(#{entry.roomNumber})</small>
                                                <small className="ml-2">
                                                    {!entry.closedAt && <a href="#" onClick={() => this.openCloseRoomConfirmation(entry.roomId, companyIndex, entryIndex)}>
                                                        {this.state.closingRoomId === entry.roomId ? 'Closing room, please wait...' : 'Close this room'}
                                                    </a>}
                                                </small>
                                                {entry.closedAt && this.roomClosedInformation(entry)}
                                            </Row>
                                            <Row>Supported Roles: {this.getSupportedRole(entry)}</Row>
                                            <Row>
                                                Interviewers: {this.getInterviewers(entry)}
                                                <span className="ml-auto small">
                                                    {!entry.closedAt && <a href="#" onClick={() => this.showAssignCandidateDialog(entry.companyName, entry.companyId, entry.roomNumber, entry.roomId, entry.jobRoles)}>
                                                        Assign candidate to this room
                                                    </a>}
                                                </span>
                                            </Row>
                                            <Table striped bordered hover size="sm" className="mt-3">
                                                <thead>
                                                    <tr>
                                                        <td>User Id</td>
                                                        <td>Selected Role</td>
                                                        <td>Last Status</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {entry.entries
                                                        .filter(e => (e.userId != null) && (e.userId !== 'PAUSE') && (e.userId !== 'GAP'))
                                                        .map(e => {
                                                            return (
                                                                <tr key={e.userId}>
                                                                    <td>
                                                                        <div>{e.name} <small><Link to={`/speedInterviewEvent/${this.state.eventId}/timeline?searchType=Candidate&searchValue=${e.userId}`} target="_blank">({e.userId})</Link></small></div>
                                                                    </td>
                                                                    <td>{this.getRoleName(entry, e.jobRoleId)}</td>
                                                                    <td>{e.hasBeenWaitlisted ? 'WAITLISTED' : e.status}</td>
                                                                    <td>
                                                                        { (e.status === 'QUEUED') &&
                                                                            <Button variant="light" size="sm" onClick={() => this.showMoveDialog(entry, e)}>Move</Button>
                                                                        }
                                                                        { (e.status !== 'ABORTED') && (entry.closedAt == null) &&
                                                                            <Button variant="danger" size="sm" onClick={() => this.showAbortInterviewWarning(entry, e)}>Abort</Button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Container>
                                    </Row>
                                );
                            })}
                        </Fragment>
                    );
                })}
            </Container>
        )
    }

}

interface State {
    eventId: string;
    eventName: string;
    eventImage: string;
    eventFinishedAt: Date;
    companies: Map<string, IRoomInfoEntry[]>;
    loading: boolean;
    selectedRoomInfoEntry: IRoomInfoEntry|null;
    selectedCandidateStatusEntry: ICandidateStatusEntry|null;
    showAbortInterview: boolean;
    showMoveDialog: boolean;
    selectedTargetRoomToMove?: string;
    showAssignCandidateDialog: boolean;
    showMessageDialog: boolean;
    assignCandidateDialogData: {
        companyName: string,
        companyId: string,
        roomNumber: number,
        roomId: string,
        roles: {id: string, title: string}[];
    },
    assignCandidateFormValue: IAssignCandidateFormValue;
    messageFormValue: IMessage;
    candidatesOnEvent: any[];
    selectedRoleForAssignCandidate?: string;
    interviewersOnEvent: string[];
    closingRoomId: string;
    closingRoomConfirmation: boolean;
    closingRoomData: any[];
    openOrCloseStageProcessing: boolean;
    creatingStage: boolean;
}

interface RouteParam {
    eventId: string;
}

interface ICandidateStatusEntry {
    userId: string;
    status: string;
    jobRoleId: string;
    name: string;
    hasBeenWaitlisted: boolean;
}

export interface IRoomInfoEntry {
    companyId: string;
    companyName: string;
    companyLogo: string;
    roomId: string;
    roomNumber: number;
    jobRoles: {id: string, title: string}[];
    performedBy: {email: string, name: string};
    closedAt: string;
    closedReason: string;
    entries: ICandidateStatusEntry[]
}

export interface IRoomInfo {
    eventId: string;
    eventName: string;
    eventLogo: string;
    eventFinishedAt: string;
    companies: IRoomInfoEntry[];
}

export interface IAssignCandidateFormValue {
    eventId: string|null;
    companyId: string|null;
    roomId: string|null;
    selectedRoleId: string|null;
    candidateIds: string[];
}

export interface IMessage {
    candidateIds: string[];
    interviewerEmails: string[];
    text: string|null;
    sender: string|null;
}
