import React from 'react';

export default class Report extends React.Component<any, any> {

    render() {
        return <div>
            <iframe src="https://datastudio.google.com/embed/reporting/d568b1db-daac-4d68-8674-85fc5ee91287/page/hVs0B"
                frameBorder="0" style={{border:0, width: '100%', height: '850px'}} title="Report" allowFullScreen />
        </div>
    }
}
