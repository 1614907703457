import React, {Fragment} from 'react';
import {functions} from '../firebase';
import Table from 'react-bootstrap/Table';
import {ToastsStore} from 'react-toasts';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

export interface Prop {
    canReplay: boolean;
}

const dateStartFormatter = new Intl.DateTimeFormat('en', {
    day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
} as any);

const dateFinishFormatter = new Intl.DateTimeFormat('en', {
    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
} as any);

export default class SpeedInterviewEventList extends React.Component<Prop> {

    state = {
        speedInterviewEvents: [],
        showLoading: false,
        candidateInfo: null,
    };

    componentDidMount() {}

    componentWillUnmount() {}

    search = async (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({
            showLoading: true,
            speedInterviewEvents: [],
            candidateInfo: null,
        });
        const formData = new FormData(e.target as HTMLFormElement);
        const searchKey = formData.get('searchKey') as string;
        let searchValue = formData.get('searchValue') as string;
        if (['CANDIDATE_ID', 'CANDIDATE_EMAIL', 'CANDIDATE_NAME'].includes(searchKey)) {
            const findCandidate = functions.httpsCallable('eventStore-api-findCandidate');
            let candidateSearchKey = 'ID';
            if (searchKey === 'CANDIDATE_EMAIL') {
                candidateSearchKey = 'EMAIL';
            } else if (searchKey === 'CANDIDATE_NAME') {
                candidateSearchKey = 'NAME';
            }
            const candidate = (await findCandidate({searchKey: candidateSearchKey, searchValue})).data;
            if (candidate == null) {
                this.setState({showLoading: false});
                return;
            }
            this.setState({candidateInfo: candidate});
            searchValue = candidate.userId;
        }
        const findEvents = functions.httpsCallable('eventStore-api-findEvents');
        return findEvents({searchKey, searchValue}).then((result) => {
            this.setState({
                speedInterviewEvents: result.data ?? []
            });
        }).catch(error => {
            ToastsStore.error(`Error ${JSON.stringify(error)} while searching for events`);
        }).finally(() => this.setState({showLoading: false}));
    }

    candidateInfo() {
        return this.state.candidateInfo as {userId: string, email: string, name: string, nickname: string}|null;
    }

    render() {
        return (
            <Fragment>
                <Form inline className="mb-3" onSubmit={this.search}>
                    <Form.Group controlId="searchKey">
                        <Form.Label className="mr-2">Filter by</Form.Label>
                        <Form.Control as="select" custom className="mr-2" name="searchKey">
                            <option value={''}>Show All</option>
                            <option value={'EVENT_ID'}>Event Id</option>
                            <option value={'EVENT_NAME'}>Event Name</option>
                            <option value={'COMPANY_ID'}>Company Id</option>
                            <option value={'COMPANY_NAME'}>Company Name</option>
                            <option value={'ROOM_ID'}>Room Id</option>
                            <option value={'CANDIDATE_ID'}>Candidate Id</option>
                            <option value={'CANDIDATE_EMAIL'}>Candidate Email</option>
                            <option value={'CANDIDATE_NAME'}>Candidate Name</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Control placeholder="Search value" name="searchValue" className="w-50 mr-2"/>
                    <Button type="submit">Search</Button>
                </Form>
                {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                {this.state.candidateInfo && <div className="mb-2 small text-muted d-flex flex-column flex-md-row">
                    <div className="mr-2">Candidate Id: <strong>{this.candidateInfo()?.userId}</strong></div>
                    <div className="mr-2">Email: <strong>{this.candidateInfo()?.email}</strong></div>
                    <div className="mr-2">Name: <strong>{this.candidateInfo()?.name}</strong></div>
                    <div className="mr-2">Nickname: <strong>{this.candidateInfo()?.nickname}</strong></div>
                </div>}
                {!this.state.showLoading && <Table responsive>
                    <thead>
                        <tr>
                            <th>Event Id</th>
                            <th>Name</th>
                            <th>Interview Duration</th>
                            <th>Time</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.speedInterviewEvents.map((e: SpeedInterviewEvent) =>
                        <tr key={e.eventId}>
                            <td><Link to={{ pathname: `/speedInterviewEvent/${e.eventId}`, state: {canReplay: this.props.canReplay} }}>{e.eventId}</Link></td>
                            <td>{e.name}</td>
                            <td>{e.interviewDuration}</td>
                            <td>{dateStartFormatter.format(new Date(e.startedAt))} - {dateFinishFormatter.format(new Date(e.finishedAt))}</td>
                            <td>
                                <Link to={`/speedInterviewEvent/${e.eventId}/roominfo`}>
                                    <Button variant="light">Rooms</Button>
                                </Link>
                            </td>
                        </tr>
                    )
                    }
                    </tbody>
                </Table>}
            </Fragment>
        )
    }

}

interface SpeedInterviewEvent {

    eventId: string;
    name: string;
    interviewDuration: number;
    startedAt: string;
    finishedAt: string;
    createdAt: string;

}
