import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export default class ActionButton extends React.Component<ActionButtonProperty> {

    state = {
        busy: false,
    };

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleClick = () => {
        this.setState({busy: true});
        this.props.handleClick(this.props.actionData).then(() => {
            this.setState({busy: false});
        }).catch(() => {
            this.setState({busy: false});
        });
    };

    render() {
        return (
            <Button onClick={this.handleClick} variant="outline-primary">
                {this.state.busy && <Spinner animation="grow" role="status" size="sm" as="span" className="mr-2" />}
                {this.props.label}
            </Button>
        );
    }

}

export interface ActionButtonProperty {

    handleClick: (e: any) => Promise<any>;
    label: string;
    actionData: any;

}
