import {TimelineEntry} from './TimelineEntry';

export class TimelinePausedEntry extends TimelineEntry {

    constructor(public key: string, public startDate: Date) {
        super(key, 'Pause', startDate);
    }

    getColor(): string {
        return '#aaa';
    }

}
