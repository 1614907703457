import React from 'react';
import './App.css';

export default class Home extends React.Component {

    render() {
        return (
            <div>
                <h4>Hireplace Event Store</h4>
                <h6>(Internal Development Tool)</h6>
                <div className="my-3">
                This Event Store is for development purpose.  It could be used for troubleshooting, debugging, recreating read/query models,
                and as a source for currently existing or planned feature such as audit logs and reporting.
                </div>
            </div>
        );
    }

}
