import React from 'react';
import './App.css';
import firebase from './firebase';
import {Redirect} from 'react-router';

export default class Logout extends React.Component {

    render() {
        // noinspection JSIgnoredPromiseFromCall
        firebase.auth().signOut();
        return (
            <Redirect to="/" />
        );
    }

}
