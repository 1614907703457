import {TimelineEntry} from './TimelineEntry';
import {TimelineFinishedEntry} from './TimelineFinishedEntry';

export class Timeline {

    private _entries: TimelineEntry[] = [];

    add(entries: TimelineEntry|TimelineEntry[]) {
        if (entries instanceof TimelineEntry) {
            this.doAdd(entries);
        } else {
            for (const entry of entries) {
                this.doAdd(entry);
            }
        }
    }

    private doAdd(entry: TimelineEntry) {
        const previousEntry = this.entries.filter(e => e.key === entry.key).pop();
        if (previousEntry && !previousEntry.standalone) {
            previousEntry.handleNextEntry(entry);
        }
        this._entries.push(entry);
    }

    get entries(): TimelineEntry[] {
        return Array.from(this._entries);
    }

    toChartRows(): any[][] {
        return this._entries
            .filter(e => !(e instanceof TimelineFinishedEntry))
            .map(e => e.toChartRow());
    }

}
