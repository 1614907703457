import React, {Fragment} from 'react';
import {functions} from '../firebase';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import {RouteComponentProps} from 'react-router';

const dateFormatter = new Intl.DateTimeFormat('en', {
    day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
} as any);

export default class EOEventList extends React.Component<RouteComponentProps & {canReplay: boolean}, State> {

    state = {
        showLoading: false,
        eoEvents: [],
    };

    search = async (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({
            showLoading: true,
            eoEvents: [],
        });
        const formData = new FormData(e.target as HTMLFormElement);
        const searchEmail = formData.get('searchEmail') as string;
        const findEOEvents = functions.httpsCallable('eventStore-api-findEOEvents');
        let result;
        try {
            if ((searchEmail != null) && searchEmail.trim().length > 0) {
                result = await findEOEvents({email: searchEmail.trim()});
            } else {
                result = await findEOEvents({email: null});
            }
            this.setState({eoEvents: result.data ?? []});
        } finally {
            this.setState({showLoading: false});
        }
    }

    render() {
        return (
            <Fragment>
                <Form inline className="mb-3" onSubmit={this.search}>
                    <Form.Control placeholder="EO Email" name="searchEmail" className="w-50 mr-2"/>
                    <Button type="submit">Search</Button>
                </Form>
                {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                {!this.state.showLoading && <Table responsive>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>EO Name</th>
                            <th>Email</th>
                            <th>Type</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.eoEvents.map((e: EOEvent) =>
                        <tr key={e.id}>
                            <td><Link to={{ pathname:`/eo/${e.id}`, state: {canReplay: this.props.canReplay} }}>{e.id}</Link></td>
                            <td>{e.eoName}</td>
                            <td>{e.eoEmail}</td>
                            <td>{e.type}</td>
                            <td>{dateFormatter.format(new Date(e.createdAt))}</td>
                        </tr>
                    )
                    }
                    </tbody>
                </Table>
                }
            </Fragment>
        )
    }

}

interface State {
    showLoading: boolean;
    eoEvents: EOEvent[];
}

interface EOEvent {

    id: string;
    eoName: string;
    eoEmail: string;
    type: string;
    createdAt: string;

}
