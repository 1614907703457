import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {RouteComponentProps} from 'react-router';
import {functions} from '../firebase';
import {Link} from 'react-router-dom';
import Col from 'react-bootstrap/esm/Col';
import Spinner from 'react-bootstrap/Spinner';
import {ToastsStore} from 'react-toasts';

const dateFormatter = new Intl.DateTimeFormat('en', {
    day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
} as any);

export default class SpeedInterviewEventDetail extends React.Component<RouteComponentProps<RouteParam> & {canReplay: boolean}, State> {

    state: State = {
        eventId: '',
        events: [],
        availableEventTypes: [],
        showLoading: false,
    };

    componentDidMount() {
        const eventId = this.props.match.params.eventId;
        this.setState({eventId});
        functions.httpsCallable('eventStore-api-findStoredEventTypes')().then(result => {
            this.setState({
                availableEventTypes: result.data ?? [],
            });
        });
    }

    search = (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({
            showLoading: true,
            events: [],
        });
        const formData = new FormData(e.target as HTMLFormElement);
        const eventType = formData.get('eventType') as string;
        const roomIdSearch = formData.get('roomIdSearch') as string;
        const candidateIdSearch = formData.get('candidateIdSearch') as string;
        const fullTextSearch = formData.get('fullTextSearch') as string;
        const findStoredEvents = functions.httpsCallable('eventStore-api-findStoredEvents');
        const data: {eventId: string, type?: string, roomId?: string, candidateId?: string, fullTextSearch?: string} = {
            eventId: this.state.eventId
        };
        if (eventType !== 'ALL') {
            data.type = eventType;
        }
        if ((roomIdSearch != null) && (roomIdSearch.trim().length > 0)) {
            data.roomId = roomIdSearch.trim();
        }
        if ((candidateIdSearch != null) && (candidateIdSearch.trim().length > 0)) {
            data.candidateId = candidateIdSearch.trim();
        }
        if ((fullTextSearch != null) && (fullTextSearch.trim().length > 0)) {
            data.fullTextSearch = fullTextSearch.trim();
        }
        return findStoredEvents(data).then(result => {
            this.setState({
                events: result.data ?? [],
            });
        }).catch(err => {
            ToastsStore.error(`Error ${JSON.stringify(err)} while searching for stored events`);
        }).finally(() => this.setState({showLoading: false}));
    };

    eventTypeOptions() {
        const options = [];
        for (const type of this.state.availableEventTypes) {
            options.push(<option value={type} key={type}>{type}</option>);
        }
        return options;
    }

    render() {
        return (
            <div>
                <Form className="mb-3" onSubmit={this.search}>
                    <Form.Row className="mb-2">
                        <Col>
                            <Form.Control as="select" custom name="eventType">
                                <option value={'ALL'}>All</option>
                                {this.eventTypeOptions()}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Control placeholder="Room Id" name="roomIdSearch"/>
                        </Col>
                        <Col>
                            <Form.Control placeholder="Candidate Id" name="candidateIdSearch"/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs={8}>
                            <Form.Control placeholder="Any Text In Event Payload (Full Text Search)" name="fullTextSearch" />
                        </Col>
                        <Button type="submit" variant="primary" className="mx-2">Search</Button>
                        <Link to={`/speedInterviewEvent/${this.state.eventId}/timeline`}>
                            <Button variant="light">View Timeline</Button>
                        </Link>
                    </Form.Row>
                </Form>
                {this.state.showLoading && <div>Preparing your data... <Spinner animation="grow"/></div>}
                {!this.state.showLoading && <Table responsive>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Type</th>
                        <th>Created At</th>
                        <th>Room Id</th>
                        <th>Candidate Id</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.events.map((e: Event) =>
                        <tr key={e.id}>
                            <td><Link to={{ pathname:`/speedInterviewEvent/${this.state.eventId}/${e.id}`, state: {canReplay: this.props.canReplay} }}>{e.id}</Link></td>
                            <td>{e.type}</td>
                            <td>{dateFormatter.format(new Date(e.createdAt))}</td>
                            <td>{e.roomId ?? ''}</td>
                            <td>{e.candidateId ?? ''}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>}

            </div>

        )
    }

}

interface State {
    eventId: string;
    events: Event[];
    availableEventTypes: string[];
    showLoading: boolean;
}

interface RouteParam {
    eventId: string;
}

interface Event {
    id: string;
    createdAt: string;
    type: string;
    eventId: string;
    roomId: string;
    candidateId: string;
}
